import axios from 'axios'

export default async function updateComment({
  id,
  status,
}: {
  id: number
  status: number
}) {
  await axios.put(`/api/v1/comment/${id}/`, {
    status,
  })
}
