import { ActionItemResult } from '@/types/action'
import { Author } from '@/types/author'
import axios from 'axios'

export default async function createAuthor({
  first_name,
  last_name,
  user_id,
  projectId,
}: {
  first_name: string
  last_name: string
  user_id?: number
  projectId?: number
}): Promise<ActionItemResult<Author>> {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }

  const { data } = await axios.post(
    '/api/v1/author/',
    {
      first_name,
      last_name,
      user_id,
    },
    {
      headers,
    }
  )
  return data
}
