import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { UserState } from '../types'

import getUserFirstName from './getUserFirstName'
import getUserRoles from './getUserRoles'
import getProjectAccessUserRoles from './getProjectAccessUserRoles'
import checkAccessUserPermission from './checkAccessUserPermission'
import hasUserAccessToProject from './hasUserAccessToProject'
import getUserLogo from './getUserLogo'
import getUserId from './getUserId'
import getUserLocalId from './getUserLocalId'
import getUserLastName from './getUserLastName'
import getUserFullName from './getUserFullName'
import getUserPosition from './getUserPosition'
import userIsAdmin from './userIsAdmin'
import userHasRole from './userHasRole'
import getUserEmail from './getUserEmail'

const getters: GetterTree<UserState, RootState> = {
  getUserLogo,
  getUserId,
  getUserLocalId,
  getUserFullName,
  getUserFirstName,
  getUserLastName,
  getUserPosition,
  getUserRoles,
  getProjectAccessUserRoles,
  checkAccessUserPermission,
  hasUserAccessToProject,
  userIsAdmin,
  getUserEmail,
  userHasRole,
}

export default getters
