import { MutationTree } from 'vuex'
import { ArticleState } from '../types'
import setArticleField from './setArticleField'
import setArticle from './setArticle'

const mutations: MutationTree<ArticleState> = {
  setArticleField,
  setArticle,
}

export default mutations
