import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { Comment } from '@/types/comment'

export default async function fetchComments({
  page,
  count,
  withItem = 'user,article',
  filter,
  sort,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<Comment>> {
  const { data } = await axios.get('/api/v1/comment/', {
    cancelToken,
    params: { page, count, with: withItem, filter, sort },
  })

  return data
}
