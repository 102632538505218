import axios from 'axios'

export default async function banUser(data: {
  id: number
  ban_from?: string
  ban_to?: string
  ban_message?: string | null
}) {
  await axios.post(`/api/v1/user/ban/${data.id}`, {
    ban_from: data.ban_from,
    ban_to: data.ban_to,
    ban_message: data.ban_message,
  })
}
