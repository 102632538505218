import { Getter } from 'vuex'
import { RootState } from '@/store/types'
import { PlatformOrigin } from '@/types/platform'
import { APP_FRONT_ENV } from '@/config/base'

const getPlatformOrigin: Getter<RootState, RootState> = (state: RootState) => {
  const origin = PlatformOrigin[state.platform]

  if (APP_FRONT_ENV === 'PROD') {
    return origin
  }

  return origin
    .replace('www.', '')
    .replace('://', `://${APP_FRONT_ENV.toLowerCase()}.`)
}

export default getPlatformOrigin
