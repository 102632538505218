import axios, { CancelTokenSource } from 'axios'
import { Field } from '@/types/action'

let cancelTokenSource: CancelTokenSource | null = null

export default async function fetchArticleMeta(): Promise<Field[]> {
  cancelTokenSource?.cancel()
  cancelTokenSource = axios.CancelToken.source()

  const {
    data: {
      meta: {
        fields: { type },
      },
    },
  } = await axios.get('/api/v1/article/meta', {
    cancelToken: cancelTokenSource.token,
  })

  return type
}
