import { PluginObject } from 'vue'
import axios from 'axios'
import { attachAuthInterceptor } from './interceptors/axios-auth'
import { attachAxiosRetryInterceptor } from './interceptors/axios-retry'
import { attachAxiosWSResponseInterceptor } from './interceptors/axios-ws-response'
import { attachAxiosErrorNotificationInterceptor } from './interceptors/axios-error-notification'

export const AxiosPlugin: PluginObject<undefined> = {
  install() {
    attachAxiosWSResponseInterceptor(axios)
    attachAuthInterceptor(axios)
    attachAxiosRetryInterceptor(axios)
    attachAxiosErrorNotificationInterceptor(axios)
  },
}
