import axios, { AxiosError, AxiosInstance } from 'axios'
import checkOnline from '@/helpers/checkOnline'

const isRetryableError = (error: AxiosError): boolean => {
  const statusCode = error?.response?.status || 0

  return (
    !axios.isCancel(error) &&
    error.code !== 'ECONNABORTED' &&
    [0, 504, 502, 500].includes(statusCode)
  )
}

export const attachAxiosRetryInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config._retryCount = config._retryCount || 0

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError) => {
      const { config } = error
      const useRetry = config?.useRetry ?? true

      if (
        useRetry &&
        config?.method &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config._retryCount < 2 &&
        isRetryableError(error)
      ) {
        return new Promise((resolve, reject) => {
          let checkOnlinePromise: Promise<boolean> | null = null

          const intervalId = window.setInterval(() => {
            checkOnlinePromise = checkOnlinePromise || checkOnline()

            checkOnlinePromise.then((onLine) => {
              if (onLine) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                config._retryCount++

                instance(config).then(resolve).catch(reject)
                window.clearInterval(intervalId)
              }

              checkOnlinePromise = null
            })
          }, 5e3)
        })
      }

      return Promise.reject(error)
    }
  )
}
