import axios, { CancelToken } from 'axios'

export default async function fetchArticleStatTotalViews({
  articleId,
  cancelToken,
}: {
  articleId: number
  cancelToken?: CancelToken
}): Promise<number> {
  const { data } = await axios.get(
    `/api/v1/statistic/article/total-views/${articleId}`,
    {
      cancelToken,
    }
  )

  return data
}
