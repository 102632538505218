import axios from 'axios'

import { Article } from '@/types/article'

export default async function createArticleFromVideo(payload: {
  video_id: number
  name: string
  image_id: number | null
}): Promise<{ item: Article }> {
  const { data } = await axios.post<{ item: Article }>(
    '/api/v1/article/video',
    {
      ...payload,
    }
  )
  return data
}
