import { Component, Prop, Vue } from 'vue-property-decorator'
import debounce from 'lodash.debounce'
import CKEditor from 'ckeditor4-vue'
import SpellCheck from './plugins/spellCheck'
import getCountLetters from '@/helpers/getCountLetters'
import sliceText from '@/helpers/sliceText'

@Component({
  components: {
    ckeditor: CKEditor.component,
  },
  inheritAttrs: false,
})
export default class Editor extends Vue {
  @Prop({ type: String })
  private readonly placeholder?: string
  @Prop({ type: Boolean, default: true })
  private readonly hiddenCounter!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean
  @Prop({ type: String, default: '' }) private readonly value!: string
  @Prop({ type: Number, default: 200 }) private readonly throttle!: number
  @Prop({ type: String, default: 'html-text' })
  private readonly valueType!: 'plain-text' | 'html-text'
  @Prop({ type: Number }) private readonly maxlength?: number
  @Prop({ type: Object }) private readonly config?: CKEDITOR.config

  private isFocused = false
  private isSettingData = false
  private localValue = ''

  private get isEmptyText() {
    return getCountLetters(this.localValue) === 0
  }

  private get enabledSpellCheck() {
    return !!this.config?.extraPlugins?.includes('sirius-spell-check')
  }

  private get localListeners() {
    const listeners = { ...this.$listeners }

    delete listeners.input
    delete listeners.focus
    delete listeners.ready

    return listeners
  }

  private get localConfig() {
    const extraPlugins = this.config?.extraPlugins
      ?.split(',')
      .filter((pluginName) => pluginName.trim() !== 'sirius-spell-check')
      .join(',')

    return this.config && { ...this.config, extraPlugins }
  }

  private get hiddenToolbar() {
    return !this.localConfig?.toolbar?.length
  }

  private onReady(editor: CKEDITOR.editor) {
    const inputListenner = (value: string) => {
      if (this.valueType === 'plain-text') {
        if (this.maxlength && getCountLetters(value) > this.maxlength) {
          value = sliceText({
            text: value,
            start: 0,
            end: this.maxlength,
          })
        }
      }

      this.localValue = value
      this.$emit('input', value)
    }

    const debouncedInput = this.throttle
      ? debounce(inputListenner, this.throttle, {
          maxWait: this.throttle,
        })
      : null

    const onInput = (value: string) => {
      if (this.isSettingData) return

      if (debouncedInput) {
        debouncedInput(value)
      } else {
        inputListenner(value)
      }
    }

    const onChangeEditor = (value = editor.getData()) => {
      this.localValue = value
      onInput(value)
      this.$emit('update:value', value)
    }

    const onFocusEditor = (event: CKEDITOR.eventInfo) => {
      this.isFocused = true

      this.$emit('focus', event, editor)
    }

    const onBlurEditor = (event: CKEDITOR.eventInfo) => {
      this.isFocused = false
      this.$emit('blur', event, editor)
    }

    const onKeyEditor = (event: CKEDITOR.eventInfo) => {
      const keyCode = event.data.domEvent.$.key
      const metaKey = event.data.domEvent.$.metaKey
      const ctrlKey = event.data.domEvent.$.ctrlKey

      if (this.valueType === 'plain-text') {
        if (keyCode === 'Enter') {
          event.cancel()

          return
        }

        const [range] = editor.getSelection().getRanges()

        if (
          range.startOffset !== range.endOffset ||
          [
            'Backspace',
            'Delete',
            'Meta',
            'ArrowLeft',
            'ArrowRight',
            'ArrowUp',
            'ArrowDown',
          ].includes(keyCode) ||
          metaKey ||
          ctrlKey
        ) {
          return
        }

        if (
          this.maxlength &&
          getCountLetters(editor.getData()) >= this.maxlength
        ) {
          event.cancel()
        }
      }
    }

    if (this.enabledSpellCheck) {
      new SpellCheck({
        editor,
        onInput: (value) => {
          onChangeEditor(value)
        },
      })
    } else {
      editor.on('change', () => {
        onChangeEditor()
      })
    }

    editor.on('afterPaste', () => {
      if (this.maxlength) {
        const pastedText = editor.getData()

        if (getCountLetters(pastedText) > this.maxlength) {
          editor.setData(
            sliceText({
              text: pastedText,
              start: 0,
              end: this.maxlength,
            }),
            {
              callback: () => {
                const range = editor.createRange()

                range.moveToElementEditEnd(range.root)

                editor.getSelection().selectRanges([range])

                editor.fire('unlockSnapshot')
              },
            }
          )
        }
      }
    })

    editor.on('focus', onFocusEditor)
    editor.on('blur', onBlurEditor)
    editor.on('key', onKeyEditor)

    editor.on('setData', () => {
      this.isSettingData = true
    })

    editor.on('afterSetData', () => {
      this.localValue = editor.getData()
      this.isSettingData = false
    })

    this.$watch(
      () => {
        return this.value
      },
      (value) => {
        this.localValue = value
      },
      {
        immediate: true,
      }
    )

    this.$watch(
      () => {
        return [this.isFocused, this.localValue, this.isSettingData].join('-')
      },
      () => {
        if (this.isFocused || this.isSettingData) return

        const oldValue = editor.getData()
        const newValue = this.localValue

        if (oldValue === newValue) return

        editor.fire('lockSnapshot')

        editor.setData(this.localValue, {
          callback: () => {
            editor.fire('unlockSnapshot')
          },
        })
      },
      {
        immediate: true,
      }
    )

    this.$watch(
      () => {
        return this.hiddenToolbar
      },
      (hiddenToolbar) => {
        const toolbarEl = document.getElementById(`cke_${editor.name}`)

        if (!toolbarEl) return

        toolbarEl.style.opacity = hiddenToolbar ? '0' : ''
        toolbarEl.style.pointerEvents = hiddenToolbar ? 'none' : ''
      },
      {
        immediate: true,
      }
    )

    this.$watch(
      () => {
        return [this.isEmptyText, this.placeholder].join('-')
      },
      () => {
        if (typeof this.placeholder !== 'string') return

        if (this.isEmptyText && this.placeholder) {
          editor
            .editable()
            .setAttribute('data-cke-editorplaceholder', this.placeholder)
        } else {
          editor.editable().removeAttribute('data-cke-editorplaceholder')
        }
      },
      {
        immediate: true,
      }
    )

    this.$emit('ready', editor)

    this.$once('hook:beforeDestroy', () => {
      debouncedInput?.cancel()
    })

    this.$once('hook:destroyed', () => {
      // Костыль для очистки памяти, т.к. происходит утечка в ckeditor.js
      Object.keys(editor).forEach((key) => {
        // @ts-ignore
        editor[key] = null
      })
    })
  }
}
