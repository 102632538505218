import axios from 'axios'
import { ActionListResult } from '@/types/action'
import { ChangeHistoryItem } from '@/types/changeHistory'

export default async function fetchChangeHistory({
  model,
  item_id,
  ...params
}: {
  model: string
  item_id: number
  count?: number
  page?: number
}): Promise<ActionListResult<ChangeHistoryItem>> {
  const { data } = await axios.get(`/api/v1/change-log/${model}/${item_id}`, {
    params,
  })

  return data
}
