import { Author } from '@/types/author'
import axios from 'axios'

export default async function editAuthor({
  id,
  data,
}: {
  id: number
  data: Author
}) {
  await axios.put(`/api/v1/author/${id}`, data)
}
